// Useful mixin for before and after dividers
@mixin beforeafter {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 102%;
  overflow: hidden;
}

// Add divider to carousel
body:not(.page-no-banner) .carouselSlides {
  position: relative;
  &:after {
      @include beforeafter;
      height: calc((110 / 1440) * 100vw);
      bottom: -1px;
      background-image: url($assets-path + "carousel-curve.svg");
  }
}
// Add divider to footer
.pageFooterWrapper,
.homefeaturecategory-homeboximpactstats {
  position: relative;
  &:after {
      @include beforeafter;
      height: calc((110 / 1440) * 100vw);
      top: -1px;
      background-image: url($assets-path + "carousel-curve.svg");
      transform: rotate(180deg);
  }
}
body.homepage .pageFooterWrapper {
  &:after {
      @include beforeafter;
      background-image: url($assets-path + "footer-curve-home.svg");
  }
}
.pageFooter {
  padding-top: $spacer * 8;
}
// Add line top of submenu
.subMenu.level2 {
  border-top: 5px solid $brand-primary;
}

// Top level nav border-radius
@media (min-width: 1025px) {
  .menuMain .topLevel li.level1 > a {
    border-radius: 5px 5px 0 0;
  }
}
// Carousel mobile
@media (max-width: map-get($breakpoints, lg)) {
  .carouselDetailWrapper {
    z-index: 1;
  }
}
// Carousel read ore
.carouselSlideReadMore {
  margin-bottom: $spacer * 1.5;
}
// Add rounded corners to card images
a[class*="image-link"] {
  border-radius: 7px;
}
// Change home feed background colour and padding
.homeFeed {
  // padding-bottom: 0;
  &:last-child {
    padding-bottom: $spacer * 3;
  }
  &:nth-child(even) {
    background-color: tint($_greengrey, 20%);
  }
}
// Home feed colours
.homeFeedBox1 .feedsTitle {
  color: $_purple;
}
.homeFeedBox2 .feedsTitle {
  color: $_orange;
}

// Home intro colour on bold text
.homeIntro strong {
  color: $brand-primary;
}
// Adjust alignment on social icons in footer
.footerBox {
  .socialIcons {
    margin-left: -12px;
  }
}
// Add panel to newsletter in footer
.footerBox[class*="ewsletter"] {
  padding: $spacer;
  align-self: start;
  border-radius: 7px;
}
// iFrame for external blog
iframe.blogIframe {
  width: 100%;
  min-height: 800px;
}
.brum-update-page .contentBlockWrapper,
.brum-update-page .contentBlockWrapper .postContent {
  max-width: $container-max-width;
}
// Round corners on card details panel
[class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.listedPostText {
  border-radius: 5px;
}

// Round corners on media images
.contentBlock .mediaImage {
  border-radius: 5px;
}

// add padding
.homefeaturecategory-homeboximpactstats {
  padding-top: $spacer * 8;
}

.mainCallToAction .cta-button.jobs {
  background-color: $_blue;
  &:hover {
    background-color: darken($_blue, 10%);
  }
}

// Mob fixes
@media (max-width: map-get($breakpoints, lg)) {
  .carouselDetailWrapper .carouselSlideDetail {
    padding-bottom: $spacer * 3;
  }
  .subMenu.level2 {
    border-top: 0;
  }
  .menuMainAlt,
  .menuMainAlt.active {
    background-color: $_grey;
    &:hover {
      background-color: $_grey;
    }
  }
}
@media (max-width: map-get($breakpoints, md)) {
}
@media (max-width: map-get($breakpoints, sm)) {
  .menuMainAlt {
    margin-bottom: -$spacer;
  }
}

// Nav - top Level - cursor hack
ul.topLevel li.level1.hasSubmenu > a { cursor: default; }


// Post/Event creation page
.eventPostCreatePage,
.pagePostCreatePage {
  .postCreateMultimedia .commentVideoWrapper,
  .postCreateTopics,
  .postCreateComments,
  .enterAddressMap { display: none; }

  fieldset.formButtons.postCreateButtons {
    border-bottom: none;
  }
}


// Published date on blogs+news - Listing
.listedPost.module-pages,
.listedPost.module-news,
.listedPost.module-blog {
  footer p.publishDatesWrapper {
    display: block !important;
  }
}

// Published date on blogs+news - Post
body.pagesPostBody,
body.newsPostBody,
body.blogsPostBody {
  .publishDetailsWrapper {
    display: block !important;
    >*:not(.publishDatesWrapper) {
      display: none;
    }
  }
}


// Post/Event creation page
.pagePostCreatePage .postCreateMultimedia .commentVideoWrapper,
.pagePostCreatePage .postCreateTopics,
.pagePostCreatePage .postCreateComments,
.pagePostCreatePage .enterAddressMap { display: none; }
.pagePostCreatePage fieldset.formButtons.postCreateButtons { border-bottom: none; }

.eventPostCreatePage .postCreateMultimedia .commentVideoWrapper,
.eventPostCreatePage .postCreateTopics,
.eventPostCreatePage .postCreateComments,
.eventPostCreatePage .enterAddressMap { display: none; }
.eventPostCreatePage fieldset.formButtons.postCreateButtons { border-bottom: none; }

.blogListingPage .listing .breadcrumbWrapper { display: none !important; }

// Remove Double Header on Job Subsite BUG 327759 //

body.subsite {
  .headerTextSubsite, 
  .subsiteBody {
    display: none; /* hide these by default and unhide where necessary, as they need to be hidden on subsite posts which appear on main site (where IMO they shouldn't even exist) but there is no way of targeting them here specifically. */
  }
}

// BUG 574451 Remove main subsite banner image, as they need to be hidden on subsite posts which appear on main site
body.subsite {
  section.carouselImageSingle.hasCaption.carousel.carouselSubsite {
    display: none;
  }
}