//
// Environment
//
$warn: true;

//
// Config values
//

$platform-name: 'birminghamvoluntaryservicecoun';


//
// Colours
//

$_green: #6db33f;
$_lightgreen: tint($_green, 50%);
$_grey: #455560;
$_blue: rgb(43, 114, 168);
$_yellow: #C8CE5C;
$_greengrey: #A5AE9D;
$_purple: #a54a97;
$_orange: #E4572E;

$brand-primary: $_green;
$brand-secondary: $_grey;

$donate-colour: $brand-secondary;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;
$site-bleed: 20px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'bvsc-simple.png';
$logo-width: 270px;
$logo-height: 84px;


//
// Typography
//

$font-family-base: 'Fira Sans', Arial, sans-serif;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links
$link-colour: $_blue;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-background-colour: $_purple;
$btn-border-radius: 5px;

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: brand; // A colour, or 'brand'
// $social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific
$social-icons-header-colour: $brand-secondary; // A colour, or 'brand'
$social-icons-header-hover-colour: brand; // A colour, or 'brand'

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;
$input-border-radius: 3px;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-image-border-radius: 7px;
$card-heading-font-size: $font-size-h4;
$card-footer-background-colour: transparent;
$card-footer-on: true;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.075;

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content

// Tagline

// Main call to action
$cta-btn-background-colour: $_orange;

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: $_grey;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-colour: white;
$nav-top-level-item-hover-background-colour: darken($_grey, 10%);
$nav-top-level-item-colour: white;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $brand-primary;

// Submenus
$nav-submenu-background-colour: $_lightgreen;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-border-radius: 5px;
$nav-submenu-item-colour: $_grey;
$nav-submenu-item-hover-colour: white;
$nav-submenu-item-hover-background-colour: $_grey;

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?
$burger-btn-background-colour: $_grey;

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: left; // left, center, right
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-mobile-background-colour: $_lightgreen;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.2);


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: 1440px;
$carousel-image-overlay: linear-gradient(69.67deg, rgba(0, 0, 0, 0.6) 33.21%, rgba(0, 0, 0, 0.4) 56.48%, rgba(0, 0, 0, 0) 74.28%); // Add a colour value or gradient here
$carousel-details-max-width: 550px;
$carousel-details-background-colour: transparent;
$carousel-details-padding: 0;
$carousel-details-margin-x: $site-bleed * 1.5;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-heading-colour: white;
$carousel-summary-colour: white;


// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-font-size: 1.5em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-details-background-colour: tint($_greengrey, 20%);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 3;
$home-intro-font-weight: normal;
$home-intro-colour: $_grey;



//
// Home features
//

$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 3;


//
// Impact stats
//
// $impact-stats-max-width: $container-max-width - 30px;
$impact-stats-background-colour: $_grey;
$impact-stats-border-radius: 11px;
$impact-stats-padding-y: $spacer * 5;
$impact-stats-background-image: 'impact2.jpg';
$impact-stats-background-image-opacity: 0.3;


// Heading
$impact-stats-heading-margin-bottom: $spacer * 1;

// Individual stat

// Figure
$impact-stats-figure-colour: $_lightgreen;

// Summary


//
// Home feeds
//
$home-feeds-padding-top: $spacer * 4;
$home-feeds-padding-bottom: $spacer * 3;


// Feeds title
$feeds-title-colour: $brand-primary;

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: white;
$footer-boxes-count: 3;
$footer-prefab: 6;
$footer-link-decoration: none;

// Footer admin links

// Newsletter
$newsletter-background-colour: tint($_greengrey, 15%);
// $newsletter-input-background-colour: tint($_greengrey, 10%);
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: $font-size-h4;
$newsletter-button-background-colour: $_purple;

// Newsletter hero - full width form above rest of footer


//
// Context container
//
$context-container-background-colour: tint($_greengrey, 30%);
$context-container-border-radius: 7px;


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;
$page-title-colour: $brand-primary;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-background-colour: $_orange;
$blockquote-border-radius: 7px;
$blockquote-colour: white;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//



//
// Home quick giving panel
//
$home-quick-giving-enabled: false;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
